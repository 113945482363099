.results {
    min-height: 800px;
    position: relative;
}

.results-loading {
    width: 100%;
}

.results-loading > .loader {
    position: static !important;
}

.results-loading .loader-svg {
    top: 0 !important;
    width: 100px !important;
}

.results-error {
    background-color: rgba(28, 170, 221, 0.05);
    border: 2px dashed rgba(28, 170, 221, 0.699);
    border-radius: 8px;
    color: black;
    margin: 0;
    padding: 20px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
}

.results-error p {
    font-size: 1.4rem;
    font-weight: 100;
    margin-bottom: 0;
    text-align: center;
}

.results-summary-dropdown-text {
    color: #007bff;
    cursor: pointer;
}

.results-none {
    color: rgb(40, 40, 40);
    font-size: 1.9rem;
    font-weight: 300;
    margin: auto;
    margin-bottom: 20px;
    margin-top: 20px;
    max-width: 900px;
}

.results-chart {
    display: inline-block;
    margin: auto;
    text-align: center;
    max-width: 100%;
}

.results tspan,
.results .recharts-default-legend {
    font-size: 1.1rem;
    font-weight: 300;
}

.results-chart-total-submissions {
    font-size: 16px;
    margin-top: 5px;
}