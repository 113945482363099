/* Main */
.login-col {
    height: 100vh;
    font-family: Roboto;
}

.login-col-left {
    background-image: linear-gradient(rgb(0, 75, 102), rgb(0, 48, 66));
}

.login-col-left-container,
.login-col-right-container {
    margin-top: 20%;
}

.cd2h-logo-wrapper {  
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 8px;
    margin-bottom: 10px;
    padding: 0 4px 0 0;
}

.cd2h-logo {
    height: 200px;
    margin: -50px 0 -60px -75px;
}

.cd2h-logo-title {
    color: rgb(9, 192, 116);
    display: inline-block;
    font-size: 2.3rem;
    font-family: 'Mukta Mahee';
    font-weight: 100;
    margin-left: -4px;
    margin-right: 10px;
    margin-top: 6px;
    vertical-align: middle;
}

/* Info box */
.infobox {
    color: white;
    float: right;
    font-family: Roboto;
    margin-right: 30px;
    margin-left: 30px;
}

.infobox-header {
    font-size: 2.5rem;
}

.infobox-desc {
    margin-bottom: 50px;
}

.infobox a {
    color: #ffffff;
    margin-left: 20px;
    text-decoration: underline;
}

/* Login box */
.loginbox {
    min-width: 400px;
    width: 50%;
}

.loginbox label {
    font-size: 1.2rem;
    font-weight: 300;
    margin-bottom: 2px;
}

#loginbox-email {
    margin-bottom: 10px;
}

.loginbox-form-group {
    position: relative;
}

.loginbox-forgot {
    color: rgb(0 123 255);
    cursor: pointer;
    left: 70%;
    margin-top: 5px;
    position: absolute;    
}

.lock {
    font-size: 1.2rem;
    position: absolute;
    left: 93%;
    top: 80%;
}

.chevron {
    float: right;
    margin-top: 5px;
    transform: scale(1.3);
}

/* Login button */
.loginbox-button {
    background-color: rgb(0, 130, 177);
    border-radius: 4px;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.24);
    color: #ffffff;
    cursor: pointer;
    font-size: 1.2rem;
    height: 60px;
    margin: 50px 0 20px;
    padding: 17px;
    transition: 0.2s;
    vertical-align: middle;
    width: 100%;
}

.loginbox-button.calling,
.loginbox-button:hover {
    background-color: rgb(0, 99, 134);
}

.loginbox input {
    color: rgb(25, 116, 150) !important;
    font-size: 1.2rem !important;
    font-weight: 500;
}

.loginbox input:focus {
    box-shadow: 0 0 4px .2rem rgba(0, 99, 134, 0.1);
}

/* Inspired by https://stackoverflow.com/questions/15726000/css-animation-similar-to-mac-os-x-10-8-invalid-password-shake */
.loginbox.invalid {
    animation: shake .5s linear;
}
/* also need keyframes and -moz-keyframes */
 @keyframes shake {
    8%, 41% { transform: translateX(-10px); }
    25%, 58% { transform: translateX(10px); }
    75% { transform: translateX(-5px); }
    92% { transform: translateX(5px); }
    0%, 100% { transform: translateX(0); }
}

.loginbox-or {
    color: rgb(120,120,120);
    font-size: 1.1rem;
    font-style: italic;
    font-weight: 300;
    margin-left: 20px;
    text-align: left;
}

.loginbox-or-inner {
    display: inline-block;
    margin: auto;
}

/* Guest login button */
.loginbox-button-guest {
    background-color: rgb(78 85 92);
    margin-top: 20px;
}

.loginbox-button-guest:hover {
    background-color: rgb(61 66 72);
}

/* Sign up button */
.loginbox-button-signup {
    background-color: rgb(0, 138, 69);
    margin-top: 20px;
}

.loginbox-button-signup:hover {
    background-color: rgb(0, 109, 54);
}

/* Footer */
.login-footer {
    bottom: 0;
    color: #ffffff;
    font-size: 0.8rem;
    position: absolute;
    text-align: center;
    width: 100%;
}

.login-footer a {
    color: #ffffff;
    text-decoration: underline;
}

.login-footer-logo-outer-container {
    height: 55px;
}

.login-footer-logo-container {
    border-radius: 2px;
    display: inline-block;
    height: 55px;
    margin: 0 10px;
    padding: 4px;
    text-align: center;
}

.login-footer-logo {
    height: 100%;
    width: 100%;
}