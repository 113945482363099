.loader {
    display: inline-block;
    height: 15px;
    margin: 8px 12px auto -5px;
    position: relative;
}

.loader:before {
    content: '';
    display: block;
    padding-top: 100%;
}

.loader-svg {
    animation: rotate 1s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 10%;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.loader-path {
    stroke-width: 2px;
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite, colorz 6s ease-in-out infinite;
    stroke-linecap: round;
}

@keyframes rotate {
    100% { transform: rotate(360deg); }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}

@keyframes colorz {
    0% { stroke: #0057e7; }
    40% { stroke: #d62d20; }
    66% { stroke: #008744; }
    80% { stroke: #ffa700; }
    100% { stroke: #0057e7; }
}