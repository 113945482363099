.model-option {
    background-color: white;
    border: 2px solid transparent;
    border-radius: 8px;
    box-shadow: 0 4px 4px 0 rgba(0,0,0,.05),0 4px 1px -2px rgba(0,0,0,.05),0 1px 10px 0 rgba(0,0,0,.05);
    color: rgb(80,80,80);
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: 300;
    margin: auto;
    margin-bottom: 15px;
    max-width: 800px;
    padding: 10px 20px 10px 40px;
    position: relative;
    text-align: left;
    transition: box-shadow 0.2s;
}

.model-option.selected {
    background-color: rgba(70, 255, 101, 0.05);
    border-color: rgba(0, 190, 0, 0.5);
}

.model-option:hover {
    background-color: rgba(70, 255, 101, 0.1);
    border-color: rgb(0, 190, 0);
}

.model-option:hover .model-option-value {
    background-color: rgba(255, 139, 44, 1);
}

.model-option-value {
    background-color: rgba(255, 139, 44, 0.7);
    border-radius: 50%;
    color: white;
    font-size: 0.9rem;
    font-weight: 500;
    left: 0;
    margin: 4px 0 0 4px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 20px;
}