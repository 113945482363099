.signup {
    min-width: 400px;
    width: 50%;
}

.signup label {
    font-size: 1.2rem;
    font-weight: 300;
    margin-bottom: 2px;
}

#signup-email, #signup-institution,
#signup-entry-code, #signup-first-name,
#signup-last-name {
    margin-bottom: 10px;
}

.signup-form-group {
    position: relative;
}

.signup-button {
    background-color: rgb(0, 138, 69);
    border-radius: 4px;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.24);
    color: #ffffff;
    cursor: pointer;
    font-size: 1.2rem;
    height: 60px;
    margin: 30px 0 20px;
    padding: 17px;
    transition: 0.2s;
    vertical-align: middle;
    width: 100%;
}

.signup-button:hover {
    background-color: rgb(0, 109, 54);
}

.signup input {
    color: rgb(25, 116, 150) !important;
    font-size: 1.2rem !important;
    font-weight: 500;
}

.signup input:hover {
    box-shadow: 0 0 4px .2rem rgba(0, 99, 134, 0.1);
}