.noclick-modal .modal-content {
    border: 2px solid rgb(7, 192, 69);
    border-radius: 0px !important;
    height: 150px;
}

.noclick-modal.complete .modal-content{
    border: 1px solid rgb(7, 192, 69) !important;
}

.noclick-modal.modal-dialog {
    max-width: 450px !important;
    z-index: 100;
}

.noclick-modal .loader,
.noclick-modal .loader-fallback {
    height: 100px;
    margin-left: 10px;
}

.noclick-modal svg {
    height: 120px;
    margin-left: 10px;
    width: 120px;
}

.noclick-modal svg circle {
    stroke-width: 2px !important;
}

.noclick-modal path,
.noclick-modal polyline {
    stroke: rgb(7, 192, 69);
}

.noclick-modal-text {
    font-size: 2rem;
    height: 100%;
    margin-top: 35px;
    text-align: center;
}