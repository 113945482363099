.maturity-model-modal {
    color: rgb(51, 51, 51);
    font-family: Roboto;
    font-weight: 300;
    margin: 8% auto !important
}

.maturity-model-modal .modal-dialog {
    margin: 50%;
}

.maturity-model-modal .modal-content {
    border: none;
    border-radius: 0;
    box-shadow: rgba(0,0,0,0.5) 0px 0px 25px 0px;
}

.maturity-model-modal .modal-header {
    background-color: rgb(0, 75, 102);
    border-radius: 0;
    color: rgb(248,248,248);
    padding: 4px 0;
}

.maturity-model-modal .modal-title {
    font-weight: 300;
    margin: 0 auto;
}

.maturity-model-modal .modal-body {
    font-size: 1.2rem;
}

.modal-backdrop {
    background-color: rgb(100,100,100);
}

/* 
 * Overrides to make sure content is
 * not pushed down, and to blur background.
 */
body.modal-open {
    padding-top: 0;
    overflow: visible !important;
}

body.modal-open .app-container {
    filter: blur(1px);
}