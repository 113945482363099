.snackbar {
    background-color: white;
    border: 1px solid transparent;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.35);
    bottom: 0;
    height: 80px;
    font-family: Roboto;
    font-size: 1.8rem;
    font-weight: 300;
    margin: 0 -500px 20px 0;
    min-width: 220px;
    padding: 10px;
    position: absolute;
    right: 0;
    transition: margin-right 0.75s;
    z-index: 100;
}

.snackbar.show {
    margin-right: 20px;
}

.snackbar-container {
    display: flex;
    height: 100%;
}

.snackbar-text-container {
    margin: auto;
    padding: 0 8px;
}

.snackbar-icon-container {
    margin: auto;
    margin-right: 8px;
}

.snackbar svg {
    height: 40px;
    width: 40px;
    margin-bottom: -8px;
    margin-left: 4px;
}

.snackbar svg circle {
    stroke-width: 2px !important;
}

.snackbar path,
.snackbar polyline {
    stroke: rgb(7, 192, 69);
}

.snackbar-text {
    color: rgb(50,50,50);
    margin-left: 0;
}

.snackbar-text.complete {
    margin-left: -12px;
}