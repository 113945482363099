.form {
    background-color: rgb(250,250,250);
    border-radius: 8px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.12),0 1px 5px 0 rgba(0,0,0,.2);
    font-family: Roboto;
    margin: auto;
    margin-bottom: 100px;
    max-width: 1200px;
    opacity: 1;
    padding: 10px 10px 10px;
    text-align: center;
}

.form-inner {
    opacity: 1;
    transition: opacity 0.75s;
}   

.form.hidden .form-inner {
    opacity: 0;
}

.form-header {
    color: rgb(40, 40, 40);
    font-size: 1.9rem;
    font-weight: 300;
    margin: auto;
    margin-bottom: 20px;
    margin-top: 20px;
    max-width: 900px;
}

.form-section + .form-section {
    border-top: 1px solid rgb(220,220,220);
    margin-top: 100px;
    padding-top: 50px;
}

.form-subheader {
    color: rgb(150,150,150);
    font-size: 1.0rem;
    margin: auto;
    margin-top: -20px;
    max-width: 800px;
}

.form-footer {
    max-width: 900px;
    text-align: center;
    margin: auto;
}

.form-header.lg {
    color: rgb(40, 40, 40);
    font-size: 2.8rem;
    max-width: 1200px;
}

.form-corner-info {
    background-color: rgba(28,168,221,0.1);
    border-radius: 8px;
    color: rgb(28,168,221);
    float: left;
    font-size: 1.7rem;
    padding: 2px 4px;
}