.model-form-desc {
    font-size: 1.2rem;
    font-weight: 300;
    width: 100%;
    text-align: center;
}

.model-form-desc-inner {
    margin: auto;
    max-width: 1000px;
}

.model-form-desc-url {
    margin-top: 15px;
}